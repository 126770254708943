<template>
  <public-template>
    <template v-slot:public-template>
      <b-row class="flex-column flex-sm-row flex-lg-row" align-h="center">
        <b-col col xs="12" sm="8" lg="4">
          <b-card
            title="Recupera tu contraseña"
            class="recovery-password-form">
            <b-card-body>
              <validation-observer ref="recoveryPasswordForm" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onRecoveryPassword)" autocomplete="off" novalidate>
                  <b-row>
                    <b-col cols="12">
                      <validation-provider vid="password" name="contraseña" v-slot="validationContext" rules="required">
                        <b-form-group id="input-password" label-for="input-password">
                          <b-form-input
                            id="input-password"
                            name="input-password"
                            type="password"
                            v-model="uploadPassword.password"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-password"
                            placeholder="Contraseña"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider name="confirma tu contraseña" rules="required|confirmed:password" v-slot="validationContext">
                        <b-form-group id="input-repeated-password" label-for="input-repeated-password">
                          <b-form-input
                            id="input-repeated-password"
                            name="input-repeated-password"
                            type="password"
                            v-model="repeatedPassword"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-repeated-password"
                            placeholder="Confirma tu contraseña"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <b-button block type="submit" variant="primary" class="full-width mb-3">
                      Guardar
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </public-template>
</template>

<script>
export default {
  name: 'PasswordRecovery',
  data () {
    return {
      uploadPassword: {
        password: null,
        token: null
      },
      repeatedPassword: null
    }
  },
  created () {
    this.uploadPassword.token = this.$route.params.recoveryToken
  },
  methods: {
    onRecoveryPassword () {
      this.$store.dispatch('updatePasswordFromRecovery', this.uploadPassword)
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(error => {
          const responseData = error.response.data
          const responseErrors = this.createFormFieldErrors(responseData)
          console.log(responseErrors)
          this.$refs.recoveryPasswordForm.setErrors(responseErrors)
          this.unlockScreen()
        })
    }
  }
}
</script>

<style lang="less">
  @import 'requestPasswordRecovery.less';
</style>
